<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="12" style="text-align: center;">
        <CSpinner
          v-if="isLoading || isULoading"
          color="info"
          style="width:3rem;height:3rem;"
        />
      </CCol>
      <CCard v-if="profiles.role_id==1" class="col-xl-12 padding-0">
        <CCardHeader>
            <CCol xl="6" class="padding-0">
              <span style="font-size: 1.3rem;">General Setting</span>
            </CCol>
        </CCardHeader>
        <CCardBody>
          <CForm v-if="!isLoading">
            <CRow class="margin-0">
            <CCol xl="6" class="padding-0 pr-2">
              <CInput
                :label="gname.cc_company_name"
                :description="errors.cc_company_name"
                placeholder="Please fill company name"
                type="text"
                vertical
                v-model="gform.cc_company_name"
              />
              <CRow class="mb-2 margin-0">
                <CCol xl="12" class="padding-0"><label>{{ gname.cc_logo }}</label></CCol>
                <CCol xl="1" class="padding-0">
                  <CButton color="danger" class="btn-sm" v-on:click="onFileRemoved"><v-icon name="times" class="v-mid"/></CButton>
                </CCol>
                <CCol xl="10" class="padding-0">
                  <input ref="inputImage" type="file" @change="onFileChanged" />
                </CCol>
                <CCol xl="1" class="padding-0">
                  <CButton color="primary" class="btn-sm" v-on:click="showFileImage"><v-icon name="eye" class="v-mid"/></CButton>
                </CCol>
                <CCol xl="12" class="padding-0"><span>{{errors.cc_logo}}</span></CCol>
              </CRow>
              <div class="form-group" role="group">
                <label>{{ gname.cc_language }}</label>
                <v-select 
                    :reduce="languages => languages.value"
                    v-model="gform.cc_language"
                    :options="languages"
                    placeholder="Please select language"
                />
                <small class="form-text text-muted w-100">{{errors.cc_language}}</small>
              </div>
              <div class="form-group" role="group">
                <label>{{ gname.cc_timezone }}</label>
                <v-select 
                    :reduce="timezones => timezones.value"
                    v-model="gform.cc_timezone"
                    :options="timezones"
                    placeholder="Please select time zone"
                />
                <small class="form-text text-muted w-100">{{errors.cc_timezone}}</small>
              </div>
              <CInput
                :label="gname.cc_awos_path"
                :description="errors.cc_awos_path"
                placeholder="Please fill awos path"
                type="text"
                vertical
                v-model="gform.cc_awos_path"
              />
              <CInput
                :label="gname.cc_radar_path"
                :description="errors.cc_radar_path"
                placeholder="Please fill radar path"
                type="text"
                vertical
                v-model="gform.cc_radar_path"
              />
              <CInput
                :label="gname.cc_api_ip"
                :description="errors.cc_api_ip"
                placeholder="Please fill api ip"
                type="text"
                vertical
                v-model="gform.cc_api_ip"
              />
            </CCol>
            <CCol xl="6" class="padding-0 pl-2">
              <CInput
                :label="gname.cc_max_wind"
                :description="errors.cc_max_wind"
                placeholder="Please fill max wind"
                type="text"
                vertical
                v-model="gform.cc_max_wind"
              />
              <CInput
                :label="gname.cc_max_temperature"
                :description="errors.cc_max_temperature"
                placeholder="Please fill max temperature"
                type="text"
                vertical
                v-model="gform.cc_max_temperature"
              />
              <CInput
                :label="gname.cc_min_visibility"
                :description="errors.cc_min_visibility"
                placeholder="Please fill min visibility"
                type="text"
                vertical
                v-model="gform.cc_min_visibility"
              />
              <CInput
                v-if="false"
                :label="gname.cc_min_sky_condition"
                :description="errors.cc_min_sky_condition"
                placeholder="Please fill min sky condition"
                type="text"
                vertical
                v-model="gform.cc_min_sky_condition"
              />
              <CInput
                :label="gname.cc_awos_late"
                :description="errors.cc_awos_late"
                placeholder="Please fill late awos data"
                type="text"
                vertical
                v-model="gform.cc_awos_late"
              />
              <CInput
                :label="gname.cc_radar_late"
                :description="errors.cc_radar_late"
                placeholder="Please fill late radar data"
                type="text"
                vertical
                v-model="gform.cc_radar_late"
              />
              <CInput
                :label="gname.cc_delete_files"
                :description="errors.cc_delete_files"
                placeholder="Please fill delete files"
                type="text"
                vertical
                v-model="gform.cc_delete_files"
              />
              <CInput
                :label="gname.mobile_version"
                :description="errors.mobile_version"
                placeholder="Please fill delete files"
                type="text"
                vertical
                v-model="gform.mobile_version"
              />
            </CCol>
            <CCol xl="12" class="padding-0">
              <CButton color="info" class="btn-block col-xl-1 float-right padding-5 mt-2" v-on:click="postGeneralconfig()">Save</CButton>
            </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CCol xl="6" class="padding-0">
              <span style="font-size: 1.3rem;">User Preference</span>
            </CCol>
        </CCardHeader>
        <CCardBody>
          <CForm v-if="!isULoading">
            <CRow class="margin-0">
            <CCol xl="6" class="padding-0 pr-2">
              <div class="form-group" role="group">
                <label>Lanud Dashboard AWOS</label>
                <multiselect
                  @input="changeAirportawos"
                  v-model="value_airportsawos"
                  :options="airports"
                  track-by="value"
                  label="label"
                  :multiple="true"
                  placeholder="Choose air base"
                  :close-on-select="false"
                  :clear-on-select="false">
                </multiselect>
                <small class="form-text text-muted w-100">{{errors.awos_dashboard}}</small>
              </div>
              <div class="form-group" role="group">
                <label>Lanud Dashboard Radar</label>
                <multiselect
                  @input="changeAirportradar"
                  v-model="value_airportsradar"
                  :options="airports"
                  track-by="value"
                  label="label"
                  :multiple="true"
                  placeholder="Choose air base"
                  :close-on-select="false"
                  :clear-on-select="false">
                </multiselect>
                <small class="form-text text-muted w-100">{{errors.radar_dashboard}}</small>
              </div>
              <div class="form-group" role="group">
                <label>Lanud AWOS</label>
                <v-select 
                    :reduce="airports => airports.value"
                    v-model="uform.awos_selected"
                    :options="airports"
                    placeholder="Please select air base"
                />
                <small class="form-text text-muted w-100">{{errors.awos_selected}}</small>
              </div>
              <div class="form-group" role="group">
                <label>Lanud Radar</label>
                <v-select 
                    :reduce="airports => airports.value"
                    v-model="uform.radar_selected"
                    :options="airports"
                    placeholder="Please select air base"
                />
                <small class="form-text text-muted w-100">{{errors.radar_selected}}</small>
              </div>
            </CCol>
            <CCol xl="6" class="padding-0 pl-2">
              <div class="form-group" role="group">
                <label>View AWOS</label>
                <multiselect
                  @input="changeAwosShow"
                  :value="value_awosshow"
                  :options="showawos"
                  track-by="value"
                  label="label"
                  :multiple="true"
                  placeholder="Choose Showing"
                  :close-on-select="false"
                  :clear-on-select="false">
                </multiselect>
                <small class="form-text text-muted w-100">{{errors.awosshow_id}}</small>
              </div>
              <div class="form-group" role="group">
                <label>Delay</label>
                <v-select 
                    :reduce="delays => delays.value"
                    v-model="uform.radar_delay"
                    :options="delays"
                    placeholder="Please select delay"
                />
                <small class="form-text text-muted w-100">{{errors.radar_delay}}</small>
              </div>
            </CCol>
            <CCol xl="12" class="padding-0">
              <CButton color="info" class="btn-block col-xl-1 float-right padding-5 mt-2" v-on:click="postUserconfig()">Save</CButton>
            </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Image"
      :show.sync="modalImage"
      :closeOnBackdrop="true"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <div v-if="!isMLoading" id="preview">
        <img v-if="url" :src="url" />
      </div>
      <template #header>
        <h6 class="modal-title">Logo</h6>
        <CButtonClose @click="modalImage = false"/>
      </template>
      <template #footer>
        <CButton @click="modalImage = false" color="light" class="text-left">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Config',
  components: {
  },
  props: {
    valueairports: { type: Array, default: () => [] },
    valueawosshow: { type: Array, default: () => [] },
  },
  data () {
    return {
      isLoading: false, isULoading: false, isMLoading: false,
      modalImage: false, formData: null,
      url: null, img_api: '', is_removeimage: null,
      profiles: { role_id: 0 },
      value_airportsawos: this.valueairports, value_airportsradar: this.valueairports,
      value_awosshow: this.valueawosshow,
      airports: [],
      showawos: [],
      generals: [],
      listrunways: [],
      languages: [
        { value: 'English', label: 'English' },
      ],
      timezones: [
        { value: 'WIB', label: 'WIB (Waktu Indonesia Barat)' },
        { value: 'WITA', label: 'WITA (Waktu Indonesia Tengah)' },
        { value: 'WIT', label: 'WIT (Waktu Indonesia Timur)' },
      ],
      delays: [
        { value: 1000, label: 'Fast' },
        { value: 3000, label: 'Normal' },
        { value: 6000, label: 'Slow' }
      ],
      gform: {
        cc_company_name: '',
        cc_logo: null,
        cc_language: '',
        cc_timezone: '',
        cc_awos_path: '',
        cc_radar_path: '',
        cc_api_ip: '',
        cc_delete_files: '',
        cc_max_wind: '',
        cc_max_temperature: '',
        cc_min_visibility: '',
        cc_min_sky_condition: '',
        cc_awos_late: '',
        cc_radar_late: '',
        mobile_version: ''
      },
      gname: {
        cc_company_name: '',
        cc_logo: '',
        cc_language: '',
        cc_timezone: '',
        cc_awos_path: '',
        cc_radar_path: '',
        cc_api_ip: '',
        cc_delete_files: '',
        cc_max_wind: '',
        cc_max_temperature: '',
        cc_min_visibility: '',
        cc_min_sky_condition: '',
        cc_awos_late: '',
        cc_radar_late: '',
        mobile_version: ''
      },
      uform: {
        awos_dashboard: [],
        radar_dashboard: [],
        awos_selected: '',
        runway_selected: [],
        radar_selected: '',
        radar_delay: ''
      },
      errors: {
        cc_company_name: '',
        cc_logo: '',
        cc_language: '',
        cc_timezone: '',
        cc_awos_path: '',
        cc_radar_path: '',
        cc_api_ip: '',
        cc_delete_files: '',
        cc_max_wind: '',
        cc_max_temperature: '',
        cc_min_visibility: '',
        cc_min_sky_condition: '',
        cc_awos_late: '',
        cc_radar_late: '',
        awos_dashboard: '',
        radar_dashboard: '',
        awos_selected: '',
        runway_selected: '',
        radar_selected: '',
        radar_delay: '',
        mobile_version: ''
      }
    }
  },
  watch: {
    'uform.awos_selected': {
        handler: function (val, oldVal) {
          if(val!=oldVal) {
            this.showawos = []
            this.uform.runway_selected = []
            this.value_awosshow = []
            if(this.listrunways[val]) {
              this.listrunways[val].forEach((item, i) => {
                var ir = i+(i+1)-1
                var il = i+(i+1)
                this.showawos[ir] = { value: 'ra:'+i+':'+item, label: 'Magnetic Winds '+item }
                this.showawos[il] = { value: 'lg:'+i+':'+item, label: 'Lightning '+item }
              })
            }
          }
        }
    },
  },
  async created() {
    this.profiles = this.$store.state.userdata
    if(this.profiles) {
      this.profiles.role_id = this.profiles.role_id?this.profiles.role_id:0
      if(this.profiles.role_id == 1) {
        await this.showGeneralconfig()
      }
    }
    await this.showUserconfig()
  },
  methods: {
    ...mapActions('configs', ['indexCFG', 'storeCFG', 'indexUCFG', 'storeUCFG']),
    showGeneralconfig() {
      this.isLoading = true
      this.generals = []
      this.indexCFG().then((response) => {
        this.generals = response.length > 0 ? response : []
        var d_cn = this.generals.findIndex(obj => obj.name ==="cc_company_name")
        var d_l = this.generals.findIndex(obj => obj.name ==="cc_logo")
        var d_lg = this.generals.findIndex(obj => obj.name ==="cc_language")
        var d_tz = this.generals.findIndex(obj => obj.name ==="cc_timezone")
        var d_ap = this.generals.findIndex(obj => obj.name ==="cc_awos_path")
        var d_rp = this.generals.findIndex(obj => obj.name ==="cc_radar_path")
        var d_ai = this.generals.findIndex(obj => obj.name ==="cc_api_ip")
        var d_df = this.generals.findIndex(obj => obj.name ==="cc_delete_files")
        var d_mw = this.generals.findIndex(obj => obj.name ==="cc_max_wind")
        var d_mt = this.generals.findIndex(obj => obj.name ==="cc_max_temperature")
        var d_mv = this.generals.findIndex(obj => obj.name ==="cc_min_visibility")
        var d_msc = this.generals.findIndex(obj => obj.name ==="cc_min_sky_condition")
        var d_al = this.generals.findIndex(obj => obj.name ==="cc_awos_late")
        var d_rl = this.generals.findIndex(obj => obj.name ==="cc_radar_late")
        var m_ver = this.generals.findIndex(obj => obj.name ==="mobile_version")
        this.gform.cc_company_name = this.generals[d_cn]?this.generals[d_cn].value:''
        var cc_logo = this.generals[d_l]?this.generals[d_l].value:''
        this.gform.cc_language = this.generals[d_lg]?this.generals[d_lg].value:''
        this.gform.cc_timezone = this.generals[d_tz]?this.generals[d_tz].value:''
        this.gform.cc_awos_path = this.generals[d_ap]?this.generals[d_ap].value:''
        this.gform.cc_radar_path = this.generals[d_rp]?this.generals[d_rp].value:''
        this.gform.cc_api_ip = this.generals[d_ai]?this.generals[d_ai].value:''
        this.gform.cc_delete_files = this.generals[d_df]?this.generals[d_df].value:''
        this.gform.cc_max_wind = this.generals[d_mw]?this.generals[d_mw].value:''
        this.gform.cc_max_temperature = this.generals[d_mt]?this.generals[d_mt].value:''
        this.gform.cc_min_visibility = this.generals[d_mv]?this.generals[d_mv].value:''
        this.gform.cc_min_sky_condition = this.generals[d_msc]?this.generals[d_msc].value:''
        this.gform.cc_awos_late = this.generals[d_al]?this.generals[d_al].value:''
        this.gform.cc_radar_late = this.generals[d_rl]?this.generals[d_rl].value:''
        this.gform.mobile_version = this.generals[m_ver]?this.generals[m_ver].value:''

        this.gname.cc_company_name = this.generals[d_cn]?this.generals[d_cn].display_name:''
        this.gname.cc_logo = this.generals[d_l]?this.generals[d_l].display_name:''
        this.gname.cc_language = this.generals[d_lg]?this.generals[d_lg].display_name:''
        this.gname.cc_timezone = this.generals[d_tz]?this.generals[d_tz].display_name:''
        this.gname.cc_awos_path = this.generals[d_ap]?this.generals[d_ap].display_name:''
        this.gname.cc_radar_path = this.generals[d_rp]?this.generals[d_rp].display_name:''
        this.gname.cc_api_ip = this.generals[d_ai]?this.generals[d_ai].display_name:''
        this.gname.cc_delete_files = this.generals[d_df]?this.generals[d_df].display_name:''
        this.gname.cc_max_wind = this.generals[d_mw]?this.generals[d_mw].display_name:''
        this.gname.cc_max_temperature = this.generals[d_mt]?this.generals[d_mt].display_name:''
        this.gname.cc_min_visibility = this.generals[d_mv]?this.generals[d_mv].display_name:''
        this.gname.cc_min_sky_condition = this.generals[d_msc]?this.generals[d_msc].display_name:''
        this.gname.cc_awos_late = this.generals[d_al]?this.generals[d_al].display_name:''
        this.gname.cc_radar_late = this.generals[d_rl]?this.generals[d_rl].display_name:''
        this.gname.mobile_version = this.generals[m_ver]?this.generals[m_ver].display_name:''

        this.url = cc_logo
        this.img_api = cc_logo
        this.gform.cc_logo = null
        this.generateFormData()
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    showUserconfig() {
      this.isULoading = true
      this.indexUCFG().then((response) => {
        response.available_airports.forEach((item, index) => {
          this.airports[index] = { value: item.id, label: item.name+" ("+item.code+")" }
          this.listrunways[item.id] = item.runway_modified
        })
        response.available_runways.forEach((item, index) => {
          var rwdata = item.split(":")
          var rwname = (rwdata[0]=='ra') ? 'Magnetic Winds ' : 'Lightning '
          var rw = (rwdata[2])?rwdata[2]:''
          this.showawos[index] = { value: item, label: rwname+rw }
        })
        /* Awos Dashboard */
        this.uform.awos_dashboard = response.awos_dashboard.map(function (obj) {
          return obj.id
        })
        this.value_airportsawos = response.awos_dashboard.map(function (obj) {
          return { value: obj.id, label: obj.name }
        })
        /* Radar Dashboard */
        this.uform.radar_dashboard = response.radar_dashboard.map(function (obj) {
          return obj.id
        })
        this.value_airportsradar = response.radar_dashboard.map(function (obj) {
          return { value: obj.id, label: obj.name }
        })
        /* Awos */
        this.uform.awos_selected = response.awos_selected ? response.awos_selected.id:''
        this.uform.runway_selected = response.runway_selected
        this.value_awosshow = this.showawos.filter(function(item) {
          if(response.runway_selected.includes(item.value)) return true
        })
        /* Radar */
        this.uform.radar_selected = response.radar_selected ? response.radar_selected.id:''
        this.uform.radar_delay = response.radar_delay?response.radar_delay:3000
        this.isULoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isULoading = false
      })
    },
    postGeneralconfig() {
      this.isLoading = true
      this.generateFormData()
      this.storeCFG(this.formData).then((response) => {
        this.showGeneralconfig()
        this.flashMessage.success({ title: 'Success', message: 'Data has been Saved' })
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    postUserconfig() {
      this.isULoading = true
      this.storeUCFG(this.uform).then((response) => {
        this.showUserconfig()
        this.flashMessage.success({ title: 'Success', message: 'Data has been Saved' })
        this.isULoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isULoading = false
      })
    },
    generateFormData(){
      this.formData = new FormData()
      this.formData.append('cc_company_name', this.gform.cc_company_name)
      if(this.gform.cc_logo) {
        this.formData.append('cc_logo', this.gform.cc_logo)
      }
      if(this.is_removeimage) {
        this.formData.append('is_remove_cc_logo', this.is_removeimage)
      }
      this.formData.append('cc_language', this.gform.cc_language)
      this.formData.append('cc_timezone', this.gform.cc_timezone)
      this.formData.append('cc_awos_path', this.gform.cc_awos_path)
      this.formData.append('cc_radar_path', this.gform.cc_radar_path)
      this.formData.append('cc_api_ip', this.gform.cc_api_ip)
      this.formData.append('cc_delete_files', this.gform.cc_delete_files)
      this.formData.append('cc_max_wind', this.gform.cc_max_wind)
      this.formData.append('cc_max_temperature', this.gform.cc_max_temperature)
      this.formData.append('cc_min_visibility', this.gform.cc_min_visibility)
      // this.formData.append('cc_min_sky_condition', this.gform.cc_min_sky_condition)
      this.formData.append('cc_awos_late', this.gform.cc_awos_late)
      this.formData.append('cc_radar_late', this.gform.cc_radar_late)
      this.formData.append('mobile_version', this.gform.mobile_version)
    },
    showFileImage() {
      this.modalImage = true
    },
    changeAirportawos(val) {
      this.value_airportsawos = val
      this.uform.awos_dashboard = val.map(function (obj) {
        return obj.value
      })
    },
    changeAirportradar(val) {
      this.value_airportsradar = val
      this.uform.radar_dashboard = val.map(function (obj) {
        return obj.value
      })
    },
    changeAwosShow(val) {
      this.value_awosshow = val
      this.uform.runway_selected = val.map(function (obj) {
        return obj.value
      })
    },
    onFileChanged(e) {
      if(e.target.files[0].size<=1024000) {
        this.gform.cc_logo = e.target.files[0];
        this.url = URL.createObjectURL(this.gform.cc_logo);
        this.errors.cc_logo = ''
      } else {
        this.errors.cc_logo = 'File max 1MB'
      }
      console.log('size', e.target.files[0].size) // 2697333 B
    },
    onFileRemoved(e) {
      if(this.img_api) {
        this.is_removeimage = 'true'
      } else {
        this.is_removeimage = null
      }
      this.gform.cc_logo = null;
      this.url = null;
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.cc_company_name = data.errors.cc_company_name ? data.errors.cc_company_name[0] : ''
        this.errors.cc_logo = data.errors.cc_logo ? data.errors.cc_logo[0] : ''
        this.errors.cc_language = data.errors.cc_language ? data.errors.cc_language[0] : ''
        this.errors.cc_timezone = data.errors.cc_timezone ? data.errors.cc_timezone[0] : ''
        this.errors.cc_awos_path = data.errors.cc_awos_path ? data.errors.cc_awos_path[0] : ''
        this.errors.cc_radar_path = data.errors.cc_radar_path ? data.errors.cc_radar_path[0] : ''
        this.errors.cc_api_ip = data.errors.cc_api_ip ? data.errors.cc_api_ip[0] : ''
        this.errors.cc_delete_files = data.errors.cc_delete_files ? data.errors.cc_delete_files[0] : ''
        this.errors.cc_max_wind = data.errors.cc_max_wind ? data.errors.cc_max_wind[0] : ''
        this.errors.cc_max_temperature = data.errors.cc_max_temperature ? data.errors.cc_max_temperature[0] : ''
        this.errors.cc_min_visibility = data.errors.cc_min_visibility ? data.errors.cc_min_visibility[0] : ''
        this.errors.cc_min_sky_condition = data.errors.cc_min_sky_condition ? data.errors.cc_min_sky_condition[0] : ''
        this.errors.cc_awos_late = data.errors.cc_awos_late ? data.errors.cc_awos_late[0] : ''
        this.errors.cc_radar_late = data.errors.cc_radar_late ? data.errors.cc_radar_late[0] : ''
        this.errors.awos_dashboard = data.errors.awos_dashboard ? data.errors.awos_dashboard[0] : ''
        this.errors.radar_dashboard = data.errors.radar_dashboard ? data.errors.radar_dashboard[0] : ''
        this.errors.awos_selected = data.errors.awos_selected ? data.errors.awos_selected[0] : ''
        this.errors.runway_selected = data.errors.runway_selected ? data.errors.runway_selected[0] : ''
        this.errors.radar_selected = data.errors.radar_selected ? data.errors.radar_selected[0] : ''
        this.errors.radar_delay = data.errors.radar_delay ? data.errors.radar_delay[0] : ''
        this.errors.mobile_version = data.errors.mobile_version ? data.errors.mobile_version[0] : ''
      }}
    }
  }
}
</script>
<style scoped>
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #preview img {
    max-height: 60vh;
  }
</style>